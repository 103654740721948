import React from "react";
import { translate } from "react-translate";
import "../../assets/stylesheets/otp-prompt-modal.css";
import {
  continueLogin,
  firebaseOneTimePassword,
} from "../../services/firebase";
import { validateEmail, validatePhone } from "../../services/ApiService";
import ApiService from "../../services/ApiService";
import MethodSelection from "../otpComponents/MethodSelection";
import PhoneInputWrapper from "../otpComponents/PhoneInputWrapper";
import EmailInputWrapper from "../otpComponents/EmailInputWrapper";
import VerifyOtpInputWrapper from "../otpComponents/VerifyOtpInputWrapper";
import SendOtpButton from "../otpComponents/SendOtpButton";
import ResendOtpSection from "../otpComponents/ResendOtpSection";
import ResendWrapper from "../otpComponents/ResendWrapper";
import { OTP_EVENTS } from "../../assets/appConstants";

class OtpPromptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      isValid: true,
      errorMessage: "",
      phoneOrEmail: "",
      step: 1,
      resendTimer: 30,
      method: "phone",
      countryCode: this.props.countryCode || "us",
      isEmailSent: false,
      resendDisabled: false,
      otpTrials: 0,
      emailResendTrials: 0
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
    this.sendOtp = this.sendOtp.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      if (this.state.step === 1) {
        this.sendOtp();
      } else {
        this.verifyOtp();
      }
    }
  }

  verifyOtp() {
    const { confirmationResult } = window;
    const { t } = this.props;

    if (confirmationResult && this.state.otp) {
      confirmationResult
        .confirm(this.state.otp)
        .then((result) => {
          continueLogin(result);
          this.props.onClose();
          //log it
          ApiService.logOtpEvent(OTP_EVENTS.SMS_VERIFY_SUCCESS);
        })
        .catch((error) => {
          this.setState({
            isValid: false,
            errorMessage: t("login.otp.verification.failed"),
          });
          //log it
          ApiService.logOtpEvent(OTP_EVENTS.SMS_VERIFY_ERROR, { error });
        });
    } else {
      this.setState({ isValid: false, errorMessage: t("login.enterOtp") });
    }
  }

  resendEmail() {
    const { t } = this.props;
    const { emailResendTrials } = this.state;
  
    if (emailResendTrials >= 4) {
      this.setState({
        isValid: false,
        errorMessage: t("login.limit.exceed"),
      });
      return;
    }
  
    ApiService.sendOneTimeLink(this.state.phoneOrEmail)
      .then(() => {
        this.setState((prevState) => ({
          isEmailSent: true,
          emailResendTrials: prevState.emailResendTrials + 1,
          resendTimer: 30,
          resendDisabled: true, 
        }));
        this.startResendTimer(); 
        //log it
        ApiService.logOtpEvent(OTP_EVENTS.OTL_RESEND_SUCCESS, { phoneOrEmail: this.state.phoneOrEmail });
      })
      .catch((error) => {
        this.setState({
          isValid: false,
          errorMessage: t("login.otl.link.failed"),
        });
        //log it
        ApiService.logOtpEvent(OTP_EVENTS.OTL_RESEND_ERROR, { phoneOrEmail: this.state.phoneOrEmail, error });
      });
  }

  sendOtp() {
    const { phoneOrEmail, method, countryCode } = this.state;
    const { t } = this.props;

    if (method === "email") {
      const isValidEmail = validateEmail(phoneOrEmail);
      if (isValidEmail) {
        ApiService.sendOneTimeLink(phoneOrEmail)
          .then(() => {
            this.setState({ step: 2, isEmailSent: true });
            this.startResendTimer()
            //log it
            ApiService.logOtpEvent(OTP_EVENTS.OTL_SEND_SUCCESS, { phoneOrEmail });
          })
          .catch((error) => {
            this.setState({
              isValid: false,
              errorMessage: t("login.otl.link.failed"),
            });
            //log it
            ApiService.logOtpEvent(OTP_EVENTS.OTL_SEND_ERROR, { phoneOrEmail, error });
          });
      } else {
        this.setState({
          isValid: false,
          errorMessage: t("login.otl.invalid.email"),
        });
      }
    } else if (method === "phone") {
      const { countryCode: separatedCountryCode, userValue } =
        this.separatePhoneNumber({
          value: phoneOrEmail,
          data: { dialCode: countryCode },
        });
      const formattedNumber = `${separatedCountryCode}${userValue}`;
      const isValidPhone = validatePhone(formattedNumber);

      if (isValidPhone) {
        firebaseOneTimePassword(formattedNumber)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            this.setState({ step: 2 });
            this.startResendTimer();
            //log it
            ApiService.logOtpEvent(OTP_EVENTS.SMS_SEND_SUCCESS, { phoneNumber: formattedNumber });
          })
          .catch(() => {
            this.setState({
              isValid: false,
              errorMessage: t("login.otp.send.failed"),
            });
          });
      } else {
        this.setState({
          isValid: false,
          errorMessage: t("login.otp.invalid.phone"),
        });
      }
    }
  }

  separatePhoneNumber(phoneInput) {
    let { value, data } = phoneInput;
    let countryCode = data.dialCode;
    if (!countryCode.startsWith("+")) {
      countryCode = `+${countryCode}`;
    }

    const countryCodePattern = new RegExp(`^\\+?${countryCode.replace("+", "")}`);
    let userValue = value.replace(countryCodePattern, "").replace(/[^\d]/g, "");

    if (userValue.startsWith("0")) {
      userValue = userValue.substring(1);
    }

    return { countryCode, userValue };
  }

  startResendTimer() {
    this.setState({ resendTimer: 30, resendDisabled: true });
    this.timerInterval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.resendTimer > 0) {
          return { resendTimer: prevState.resendTimer - 1 };
        } else {
          clearInterval(this.timerInterval);
          return { resendDisabled: false };
        }
      });
    }, 1000);
  }

  resendOtp() {
    const { t } = this.props;
    const { otpTrials, method } = this.state;
  
   if (method === "phone") {
      if (otpTrials >= 2) {
        this.setState({
          isValid: false,
          errorMessage: t("login.limit.exceed"),
        });
        return;
      }
  
      try {
        this.sendOtp();
        this.setState((prevState) => ({
          otpTrials: prevState.otpTrials + 1,
        }));
        ApiService.logOtpEvent(OTP_EVENTS.SMS_RESEND_SUCCESS);
      } catch (error) {
        ApiService.logOtpEvent(OTP_EVENTS.SMS_RESEND_ERROR, { error });
      }
    }
  }

  onInputChange = (value, data) => {
    this.setState({
      phoneOrEmail: value,
      countryCode: data.dialCode,
      isValid: true,
      isEmailSent: false,
      errorMessage: "",
    });
  };

  onMethodChange = (event) => {
    this.setState({
      method: event.target.value,
      phoneOrEmail: "",
      step: 1,
      isValid: true,
      isEmailSent: false,
      errorMessage: "",
      otp: "",
      resendTimer: 30,
      resendDisabled: false,
      otpTrials: 0,
    });
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  };

  render() {
    const { t, countryCode } = this.props;
    const {
      isValid,
      errorMessage,
      phoneOrEmail,
      step,
      resendTimer,
      method,
      isEmailSent,
      resendDisabled,
    } = this.state;
    const isPhoneOrEmailEmpty = phoneOrEmail.trim() === "";
    const isOtpEmpty = this.state.otp.trim() === "";

    return (
      <div className="otp-prompt-top">
        <div className="otp-prompt-blur"></div>
        <div className="prompt-content">
          {step === 1 && (
            <>
              <MethodSelection
                method={method}
                onMethodChange={this.onMethodChange}
                t={t}
              />
              <div className="input-wrapper">
                {method === "phone" ? (
                  <PhoneInputWrapper
                    countryCode={countryCode}
                    phoneOrEmail={phoneOrEmail}
                    onInputChange={this.onInputChange}
                  />
                ) : (
                  <EmailInputWrapper
                    phoneOrEmail={phoneOrEmail}
                    setPhoneOrEmail={(value) =>
                      this.setState({
                        phoneOrEmail: value,
                        isValid: true,
                        errorMessage: "",
                      })
                    }
                    isValid={isValid}
                    t={t}
                  />
                )}
              </div>
            </>
          )}
          {!isValid && <div className="error-message">{errorMessage}</div>}
          {step === 2 && (
            <>
              {method === "email" ? (
                <ResendWrapper phoneOrEmail={phoneOrEmail} t={t} />
              ) : (
                <VerifyOtpInputWrapper
                  otp={this.state.otp}
                  setOtp={(otp) => this.setState({ otp })}
                />
              )}
            </>
          )}
          {!(method === "email" && isEmailSent) && (
            <SendOtpButton
              step={step}
              isPhoneOrEmailEmpty={isPhoneOrEmailEmpty}
              isOtpEmpty={isOtpEmpty}
              sendOtp={this.sendOtp}
              verifyOtp={this.verifyOtp}
              t={t}
            />
          )}
          {step === 2 && method === "phone" && (
            <ResendOtpSection
              resendTimer={resendTimer}
              resendOtp={this.resendOtp}
              resendDisabled={resendDisabled}
              t={t}
            />
          )}
            {step === 2 && method === "email" && (
            <ResendOtpSection
              resendTimer={resendTimer}
              resendOtp={this.resendEmail}
              resendDisabled={resendDisabled}
              t={t}
            />
          )}
        </div>
      </div>
    );
  }
}

export default translate("login")(OtpPromptModal);
