import React from "react";
import {supportedLanguages} from "../../../assets/i18h/appTranslation";
import "../../../assets/stylesheets/language-selector.css";

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: this.props.language.toLowerCase(), listOpen: false, isModal: !!props.isModal,
    };

    this.handleList = this.handleList.bind(this);
    this.onBodyClick = this.onBodyClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onBodyClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onBodyClick);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.setState({
        selectedLanguage: this.props.language,
      });
    }
  }

  switchLang(langName) {
    this.setState({
      selectedLanguage: langName, listOpen: false,
    });
    this.props.switchLang(langName);
  }

  handleList() {
    this.setState({
      listOpen: !this.state.listOpen,
    });
  }

  onBodyClick(e) {
    const classList = e.target.classList;
    if (this.state.listOpen) {
      if (!classList.contains('selector') && !classList.contains('lang')) {
        this.setState({
          listOpen: false,
        });
      }
    }
  }

  selectedIcon() {
    return (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.036"
      height="14.941"
      viewBox="0 0 20.036 14.941"
    >
      <g>
        <path
          fill="#141414"
          d="M845.513 933.705a1 1 0 0 1 0-1.417l1.417-1.417a1 1 0 0 1 1.417 0l4.387 4.387 9.4-9.4a1 1 0 0 1 1.417 0l1.417 1.417a1 1 0 0 1 0 1.417l-11.521 11.521a1 1 0 0 1-1.417 0z"
          transform="translate(0 -.001) translate(-845.22 -925.568)"
        />
      </g>
    </svg>);
  }

  renderLangList() {
    const supportedLang = supportedLanguages;
    const uiLang = this.state.selectedLanguage;
    const list = [];

    Object.keys(supportedLang).forEach((key, index) => {
      const selected = key === uiLang;
      const langName = supportedLang[key];

      list.push(<div
        className={`lang ${selected ? "selected" : ""}`}
        onClick={() => {
          this.switchLang(langName);
        }}
        key={key}
      >
        <div className="txt">{langName}</div>
        {selected && <div className="icon">{this.selectedIcon()}</div>}
      </div>);

      if (index < Object.keys(supportedLang).length - 1) {
        list.push(<div className="sep" key={`sep-${key}`}/>);
      }
    });

    return list;
  }

  render() {
    const {selectedLanguage, listOpen, isModal} = this.state;
    const langList = this.renderLangList();
    return (<div className={`language-selector ${isModal ? 'modal' : ''}`}>
      <div className="selector" onClick={this.handleList}>
        {selectedLanguage}
      </div>
      {listOpen && <div className="lang-list">{langList}</div>}
    </div>);
  }
}

export default LanguageSelector;
