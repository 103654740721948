import { initializeApp } from "@firebase/app";
import ApiService, { validatePhone } from "./ApiService";
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  SAMLAuthProvider,
  OAuthProvider,
  getRedirectResult,
  useDeviceLanguage,
  signInWithEmailAndPassword,
  setPersistence,
  inMemoryPersistence,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  // sendPasswordResetEmail,
  FacebookAuthProvider,
  signInWithCustomToken,
  onAuthStateChanged,
} from "@firebase/auth";
import SystemPromptService from "./systemPromptService";
import {
  MOBILE_HOSTS,
  PROVIDERS_TYPE,
  FIREBASE_CONFIG,
  LOG_SEVERITIES,
  MESSAGES_TYPES,
  REDIRECT_TYPES,
  OTP_EVENTS,
  AUTH_ABSENT_TOKEN_STUB,
} from "../assets/appConstants";
import { ALERTS } from "../components/common/systemAlert";
import { getAnalytics } from "@firebase/analytics";
import { postMessage, redirect } from "./utils";

//<editor-fold desc="init firebase">
SystemPromptService.showSpinner();

const app = initializeApp(FIREBASE_CONFIG());
export const auth = getAuth(app);

getRedirectResult(auth)
  .then((res) => {
    if (res) {
      postMessage(MESSAGES_TYPES.PROVIDER_LOGIN_END);
      continueLogin(res);
    }
  })
  .catch(firebaseErrorHandling);

getAnalytics(app);

setPersistence(auth, inMemoryPersistence).catch((error) =>
  console.error(error.message)
);

// const analytics = getAnalytics(app);
//</editor-fold>

//<editor-fold desc="login options">
export const firebaseProviderLogin = (providerType, phoneNumber) => {
  postMessage(MESSAGES_TYPES.PROVIDER_LOGIN);

  let provider;
  switch (providerType) {
    case PROVIDERS_TYPE.GOOGLE:
      ApiService.logIt("login with google");
      provider = new GoogleAuthProvider();
      break;
    case PROVIDERS_TYPE.SAML:
      ApiService.logIt("login with saml");
      provider = new SAMLAuthProvider("saml.clalit");
      break;
    case PROVIDERS_TYPE.APPLE:
      ApiService.logIt("login with apple");
      provider = new OAuthProvider("apple.com");
      break;
    case PROVIDERS_TYPE.FACEBOOK:
      ApiService.logIt("login with facebook");
      provider = new FacebookAuthProvider();
      break;
    case PROVIDERS_TYPE.OTP:
      ApiService.logIt("login with OTP");
      firebaseOneTimePassword(phoneNumber);
      break;

    default:
      ApiService.logIt(`no argument passed`, LOG_SEVERITIES.WARNING);
  }

  // To apply the default browser preference instead of explicitly setting it.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useDeviceLanguage(auth);
  let host = ApiService.getAppHost();
  //on mobile user redirect and on web use pop up
  if (Object.values(MOBILE_HOSTS).indexOf(host) > -1) {
    signInWithRedirect(auth, provider)
      .then(continueLogin)
      .catch(firebaseErrorHandling);
  } else {
    signInWithPopup(auth, provider)
      .then((res) => continueLogin(res))
      .catch(firebaseErrorHandling);
  }
};

export const firebasePasswordLogin = (identifier, password) => {
  ApiService.logIt(`login with password email is: ${identifier}`);
  SystemPromptService.showSpinner();
  if (validatePhone(identifier)) {
    ApiService.phoneLogin(identifier, password).then(finishLogin);
  } else {
    signInWithEmailAndPassword(auth, identifier, password)
      .then(continueLogin)
      .catch(firebaseErrorHandling);
  }
};

export const LoginWithCustomToken = (customToken) => {
  return signInWithCustomToken(auth, customToken);
};

// //OTP function
export const firebaseOneTimePassword = (phoneNumber) => {
  return new Promise((resolve, reject) => {
    if (!window.firebaseAuth) {
      window.firebaseAuth = getAuth();
    }

    const recaptchaContainer = document.getElementById("recaptcha-container");
    if (recaptchaContainer) {
      recaptchaContainer.remove();
    }

    document.body.appendChild(Object.assign(document.createElement('div'), { id: 'recaptcha-container' }));

    if (window.recaptchaVerifier) {
      window.recaptchaVerifier = null;
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      window.firebaseAuth
    );

    signInWithPhoneNumber(window.firebaseAuth, phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        resolve(confirmationResult);
      })
      .catch((error) => {
        console.error("Error during sign-in", error);
        //log it
        ApiService.logOtpEvent(OTP_EVENTS.SMS_SEND_ERROR, { phoneNumber, error });
        reject(error);
      });
  });
};


//</editor-fold>

//<editor-fold desc="login handlers">
export function continueLogin(userCredentials) {
  let user = userCredentials.user;
  ApiService.loginHelper(user.uid, user.accessToken).then((res) => {
    let host = ApiService.getAppHost();
    if (
      res.accountType === "therapist" &&
      Object.values(MOBILE_HOSTS).indexOf(host) > -1
    ) {
      //if host is mobile, block therapist from logging
      SystemPromptService.showAlert(ALERTS.THERAPIST_ON_MOBILE);
    } else {
      finishLogin(res);
    }
  });
}

export const finishLogin = (loginData) => {
  if (loginData.accountData) {
    //go onboarding
    //server sends account data for onboarding. user didn't complete it yet
    const accountObj = loginData.accountData;
    accountObj.accountId = loginData.accountId;

    accountObj.loginData = {
      encryptedAccountId: loginData.encryptedAccountId,
      customToken: loginData.customToken,
    };
    loginData.immediateRedirect = false;
    postMessage(MESSAGES_TYPES.LOGIN_SUCCESS, loginData);
    redirect(REDIRECT_TYPES.ONBOARDING, { customToken: loginData.customToken });
  } else {
    //back to host
    loginData.immediateRedirect = true;
    postMessage(MESSAGES_TYPES.LOGIN_SUCCESS, loginData);
  }
};
//</editor-fold>

//<editor-fold desc="error handling">
function firebaseErrorHandling(error) {
  ApiService.logIt(
    "caught firebase error:" + ApiService.findErrorDescription(error),
    LOG_SEVERITIES.NOTICE,
    error
  );
  const errorMessage = error.message;
  let message, title;
  switch (true) {
    case errorMessage.indexOf("auth/wrong-password") >= 0:
      title = "wrong.password.title";
      // message = "wrong.password.message";
      break;
    case errorMessage.indexOf("auth/invalid-email") >= 0:
      title = "invalid.email.title";
      message = "invalid.email.message";
      break;
    case errorMessage.indexOf("auth/user-not-found") >= 0:
    case errorMessage.indexOf("auth/admin-restricted-operation") >= 0:
      title = "user.not.exist.title";
      message = "user.not.exist.message";
      break;
    case errorMessage.indexOf("auth/user-disabled") >= 0:
      title = "disabled.account.title";
      message = "disabled.account.message";
      break;
    case errorMessage.indexOf("auth/network-request-failed") >= 0:
      title = "network.issue.title";
      message = "network.issue.message";
      break;
    default:
      message = "general.message";
      title = "general.title";
  }
  SystemPromptService.showAlert({
    //TODO const
    title,
    content: message,
    btnCloseTitle: "close",
    btnAction: {
      show: false,
    },
  });
}

//</editor-fold>

export const getIdToken = async function () {
  //if auth user is null, wait for firebase to load
  return await new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdToken()
          .then((payload) => {
            resolve(payload);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve(AUTH_ABSENT_TOKEN_STUB);
      }
    });
  });
};

export const getFirebaseUser = async function (customToken = null) {
  //if auth user is null, wait for firebase to load
  return await new Promise(async (resolve, reject) => {
    if (customToken) {
      await signInWithCustomToken(auth, customToken);
    }
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user);
      } else {
        reject(AUTH_ABSENT_TOKEN_STUB);
      }
    });
  });
};
