import React from 'react';
import { translate } from "react-translate";

const ToggleSwitch = ({ toggleOtpPrompt, otpPrompt,t }) => {
  return (
    <div className="toggle-switch">
      <div 
        className={`toggle-option ${!otpPrompt ? 'active' : ''}`} 
        onClick={() => toggleOtpPrompt(true)}
      >
        {t("login.otp.userandpassword")}  
      </div>
      <div 
        className={`toggle-option ${otpPrompt ? 'active' : ''}`} 
        onClick={() => toggleOtpPrompt(false)}
      >
        {t("login.otp.txt")}
      </div>
    </div>
  );
};

export default translate("login")(ToggleSwitch);
