import React from "react";
import "../../assets/stylesheets/gip-login-modal.css";
import * as icons from "../../assets/svg/login-icons";
import { convertPhoneToMail, validateEmail, validatePhone } from "../../services/ApiService";
import { firebaseOneTimePassword, firebasePasswordLogin, firebaseProviderLogin } from "../../services/firebase";
import { PROVIDERS_TYPE } from "../../assets/appConstants";
import { translate } from "react-translate";
import LanguageSelector from "../onBoarding/components/language-selector";
import { supportedLanguages } from '../../assets/i18h/appTranslation';
import {
  ClosedEyeIcon, OpenEyeIcon,
} from "../../assets/svg/password-modal-icons";
import { OTPIcon } from "../../assets/svg/login-icons";
import OtpPrompt from "./OtpPromptModal";
import CloseButton from "../common/close-button";
import ToggleSwitch from "../common/ToggleSwitch";

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true, emailErrMsg: "",
      passwordErrMsg: "",
      language: props.language,
      showPassword: false,
      inputType: 'password',
      otpPrompt: false
    };

    this.onInputFocus = this.onInputFocus.bind(this);
    this.toggleOtpPrompt = this.toggleOtpPrompt.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    //for autofill detect
    setTimeout(this.checkInputs, 200);
    setTimeout(this.checkInputs, 600);
    document.addEventListener("keydown", this.handleKeyPress);
  }

  checkInputs() {
    const inputs = document.querySelectorAll(".gip-input");
    const autofilledInputs = document.querySelectorAll("input:-webkit-autofill");
    autofilledInputs.forEach((input) => {
      if (input.offsetParent) input.offsetParent.classList.add("hasValue");
    });
    inputs.forEach((input) => {
      if (input.value) input.classList.add("hasValue");
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open !== prevProps.open) {
      setTimeout(this.checkInputs, 200);
      setTimeout(this.checkInputs, 600);
    }
    if (this.props.language !== prevProps.language) {
      this.setState({
        language: this.props.language,
      });
    }
  }

  handleKeyPress(e) {
    if (e.key === "Enter" && !this.state.otpPrompt) {
      this.submitLogin(e);
    }
  }

  togglePassword() {
    const { showPassword } = this.state;
    let _show = !showPassword;
    let _inputType = _show ? "text" : "password";
    this.setState({
      inputType: _inputType, showPassword: _show,
    });
    let password = document.querySelector("#loginPassword");
    password.focus();
  }

  onInputFocus(e) {
    this.checkInputs();
    e.target.offsetParent.classList.add("hasValue");
    this.setState({
      isValid: true,
    });
    e.target.classList.remove('invalid');
    if (document.activeElement.tagName === 'INPUT') {
      if (typeof document.activeElement.scrollIntoViewIfNeeded === 'function') {
        window.setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 0);
      } else {
        window.setTimeout(() => {
          document.activeElement.scrollIntoView(false);
        }, 0);
      }
    }
  }

  toggleOtpPrompt() {
    this.setState(prevState => ({
      otpPrompt: !prevState.otpPrompt
    }));
  }

  onInputBlur(e) {
    this.checkInputs()
    if (e.target.value) return;
    e.target.offsetParent.classList.remove("hasValue");
  }

  submitLogin(e) {
    const { t } = this.props;
    e.preventDefault();
    let identifier = document.querySelector("#loginEmail");
    let password = document.querySelector("#loginPassword");
    let emailErrMsg, passwordErrMsg, isValid = true;
    if (!identifier.value) {
      emailErrMsg = t("login.email.error");
      isValid = false;
    }
    if (!password.value) {
      passwordErrMsg = t("login.password.error");
      isValid = false;
    }
    if (!validateEmail(identifier.value) && !validatePhone(identifier.value)) {
      emailErrMsg = t("login.email.invalid");
      isValid = false;
    }

    if (password.value && password.value.length < 8) {
      passwordErrMsg = t("login.password.invalid");
      isValid = false;
    }

    if (!isValid) {
      this.setState({
        emailErrMsg, passwordErrMsg, isValid,
      });
    } else {
      this.setState({
        isValid,
      }, () => {
        firebasePasswordLogin(identifier.value, password.value);
      });
    }
  }

  switchLanguage(lang) {
    this.setState({
      language: lang,
    });
    this.props.setTranslation(lang);
  }

  render() {
    const { open, t, countryCode } = this.props;
    const { emailErrMsg, passwordErrMsg, isValid, language, inputType, showPassword, otpPrompt } = this.state;
    const isHebrew = language === supportedLanguages.he;
    const QA = (document.domain.indexOf("qa-ajrpwavu4a") > -1) ? " (QA)" : ""

    const isProd = window.location.hostname.startsWith("login.wizecare.com")
    return (open && (<div className={"gip-modal-wrapper"}>

      <div className="gip-modal-content">
        <LanguageSelector
          isModal={true}
          language={language}
          switchLang={this.switchLanguage.bind(this)}
        />
        <CloseButton />
        <div className="title">{t("login.title")}</div>
        <div className={`form-wrapper ${isHebrew ? 'heb' : ''}`}>
          <div className="input-wrapper">
            <ToggleSwitch toggleOtpPrompt={this.toggleOtpPrompt} otpPrompt={otpPrompt}/>
          </div>

           {otpPrompt && <OtpPrompt countryCode={countryCode} onClose={() => this.setState({ otpPrompt: false })} />}

          <div className="input-wrapper">
            <div className="input-label">{t("login.email")}</div>
            <div className="input-floating-label">{t("login.email")}</div>
            <input
              type="email"
              id={"loginEmail"}
              onFocus={this.onInputFocus.bind(this)}
              onBlur={this.onInputBlur.bind(this)}
              onChange={this.checkInputs}
              className={`gip-input ${!isValid ? 'invalid' : ''}`}
            />
            {!isValid && (<div className={"error-message"}>{emailErrMsg}</div>)}
          </div>
          <div className="input-wrapper">
            <div className="input-label">{t("login.password")}</div>
            <div className="input-floating-label">
              {t("login.password")}
            </div>
            <input
              type={inputType}
              id={"loginPassword"}
              onFocus={this.onInputFocus.bind(this)}
              onBlur={this.onInputBlur.bind(this)}
              onChange={this.checkInputs}
              className={`gip-input ${!isValid ? 'invalid' : ''}`}
            />
            <div
              className="eye-icon"
              onClick={this.togglePassword.bind(this)}
            >
              {showPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
            </div>
            {!isValid && (<div className={"error-message"}>{passwordErrMsg}</div>)}
          </div>
        </div>
        <div className="btn-wrapper">
          <div className="action-btn" onClick={this.submitLogin.bind(this)}>
            {t("login.btn")}
          </div>
        </div>
        <div
          className="forgot-password"
          onClick={() => {
            this.props.openPasswordModal();
            this.props.handleClose();
          }}
        >
          {t("login.password.btn")}
        </div>
        <div className="hr-title">{t("login.separator")}</div>
        <div className="options-wrapper">
          <div
            className="option"
            onClick={() => {
              firebaseProviderLogin(PROVIDERS_TYPE.GOOGLE);
            }}
          >
            <div className="icon google">{icons.GoogleIcon()}</div>
            Google
          </div>

          <div
            className="option"
            onClick={() => {
              firebaseProviderLogin(PROVIDERS_TYPE.SAML);
            }}
          >
            <div className="icon sso">{icons.SsoIcon()}</div>
            Single Sign-On 
          </div>
        </div>
      </div>
    </div>));
  }
}

export default translate("login")(LoginModal);
